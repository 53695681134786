@import "scss/_variables.scss";

.preferred-subjects {
  padding-top: 16px;

  &--error {
    .preferred-subjects__header {
      color: #fc694f;
    }
  }

  &__header {
    line-height: 1.43;
    color: $colorTextLightGrey;
    font-size: $fontSizeMd;
    margin-bottom: 24px;
    transition: all .3s;
  }

  &__dropdown-title {
    @include fontFamilyBolder;
    color: $colorText;
  }
}
