@import 'scss/_variables.scss';

.profile-page {
  padding: 0 14px;
  position: relative;

  .profile-static-header {
    position: fixed;
    top: 58px;
    left: 0;
    width: 100%;
    text-align: center;
    padding-top: 20px;
    background: #fff;
    z-index: 1;
  }

  @include person-common-info;

  .person-common-info {
    padding: 16px 0;
    margin-bottom: 0;
    
    &:not(:last-child) {
      border-bottom: 1px solid $color05;
    }

    .person-common-info-title {
      padding: 0;
      max-width: none;
      text-align: left;
      color: $colorTextDark;
      font-size: 18px;
      line-height: 1.33;
      text-transform: none;
    }

    .personal-info-item {
      display: flex;
      align-items: baseline;

      .profile_birth {
        display: flex;

        > span:not(.profile-noneditable-field) {
          display: block;
          min-width: 153px;
        }
      }
      .profile-noneditable-field {
        margin: 0;
        color: $colorAction10;
        margin-left: 22px;
      }

      &__time-zone {
        .profile-noneditable-field {
          margin-left: 20px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      &__title {
        min-width: 153px;
      }

      &__value {
        color: $colorAction10;
        line-height: 1.5;
        margin-left: 22px;

        &--black {
          color: $color10;
          line-height: 1.5;
          margin-left: 0px;
        }

        &--no-margin {
          margin-left: -24px;
        }
      }
    }
  }

  &__logout-block {
    display: flex;
    align-items: center;
    color: #e5264b;
    max-width: 80px;

    .exit-icon {
      display: block;
      margin-left: 8px;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23E5264B'%3E%3Cg%3E%3Cpath d='M10.995 5c.533 0 .966.432.966.966 0 .496-.373.904-.853.96l-.113.006H5.93v10.134h5.065c.495 0 .903.374.96.855l.006.112c0 .496-.373.904-.853.96l-.113.007h-6.03c-.495 0-.903-.373-.959-.854L4 18.033V5.966c0-.496.373-.904.853-.96L4.966 5h6.029zm5.185 2.788l.091.08 3.446 3.448c.026.027.05.054.074.083l-.074-.083c.04.04.075.082.107.126l.047.074.05.102.047.133.025.135L20 12l-.003.069-.012.1-.035.138-.039.098-.054.101-.07.098-.07.079-3.446 3.448c-.377.377-.988.377-1.365 0-.348-.348-.375-.897-.08-1.276l.08-.09 1.795-1.799h-5.706c-.533 0-.966-.433-.966-.966 0-.496.373-.904.853-.96l.113-.007h5.708l-1.797-1.797c-.348-.349-.375-.897-.08-1.276l.08-.091c.348-.348.895-.375 1.274-.08z' transform='translate(-75 -995) translate(75 995)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }
}
