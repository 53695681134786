.not-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  padding: 0 8px;
  text-align: center;
  width: 100%;
  height: 100vh;
  z-index: 2147483647;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__text {
    font-size: 18px;
  }
}
