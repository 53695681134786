@import 'scss/_variables.scss';
@import './mixin.scss';

.menu {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
  z-index: 1;
  padding: 10px 0;
  border-top: 1px solid $color05;

  @include menu-mixin;

  &.menu-selectable {
    position: relative;
    width: auto;
    border: none;
  }

  ul li a .svg-icon {
    fill: #a7a8ac;
  }
}
