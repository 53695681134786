@import 'scss/_variables.scss';

.orders-cards {
  position: relative;
  min-height: calc(100vh - 48px);

  &.my-orders {
    padding-top: 45px;
  }

  &.find-orders {
    padding-top: 47px;
  }
}

.sorting-block {
  padding: 8px 16px;
  box-shadow: inset 0 -1px 0 0 rgba(198,198,198,0.5);
  display: flex;
  color: $colorTextLightGrey;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 1;
  background-color: #fff;

  .sorting-items {
    display: flex;
  }
  
  &__item {
    border-radius: 16px;
    border: solid 1px #98a7b3;
    line-height: 1.43;
    padding: 4px 12px;
    transition: all .3s;
    position: relative;

    &:not(:last-child) {
      margin-right: 8px;
    }

    &--fill {
      background-color: $colorAction10;
      color: #fff;
    }

    &--active {
      background-color: $colorAction10;
      color: #fff;
      padding: 4px 12px 4px 22px;      

      &:before {
        content: '\2193';
        position: absolute;
        left: 8px;
      }

      &.isAsc {
        &:before {
          transform: rotate(-180deg);
        }
      }

    }
  }
}
