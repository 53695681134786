.menu {
  .BrainhubCarousel__container {
    .BrainhubCarousel {
      &__trackContainer {
        .BrainhubCarousel__track {
          justify-content: flex-start;
        }
      } 
    }
  }
}
