html,
body {
  width: auto;
  height: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.no-scroll {
  overflow: hidden;
}

.container {
  width: 100%;
  padding: 0 8px;

  &.single-page-container {
    padding: 0;

    .order-tab {
      padding: 8px 0;
    }
  }
}

#root {
  padding: 48px 0 58px 0;
}



.nav-sub,
.menu {
  padding: 8px 20px;
  border: none;
  
  &--bottom {
    box-shadow: inset 0 1px 0 0 rgba(198, 198, 198, 0.5);
  }

  ul {
    display: flex;
    justify-content: space-between;

    li {
      text-align: center;

      a {
        color: $colorText;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        padding: 8px 5px;
        margin: -8px 0;
        position: relative;
        white-space: nowrap;

        &.active {
          color: $colorAction11;

          .svg-icon {
            fill: $colorAction11;
          }
        }

        .svg-icon {
          width: 24px;
          height: 24px;
          margin-bottom: 4px;
        }

        @include breakpoint(medium) {
          padding: 0;
        }

        @include new-msg;

        &:before {
          right: 0px;
          top: 4px;
        }

        &.new--usd:not(.new--warnings):before {
          top: 0;
        }

        &.new--usd:not(.new--warnings):after {
          top: -4px;
          right: -4px;
        }

        &.new--warnings:not(.new--usd):before {
          top: -2px;
        }

        &.new.my-order:before {
          right: 22px;
          top: 6px;
          width: 6px;
          height: 6px;
        }
      }
    }
  }
}

.nav-sub {
  position: fixed;
  top: 48px;
  background: #fff;
  z-index: 1;
  width: 100%;

  &--top {
    box-shadow: inset 0 -1px 0 0 rgba(198, 198, 198, 0.5);
    padding: 20px 8px 8px 8px!important;

    ul li a.active {
      border-bottom: 2px solid;
    }
  }
}

.page-not-result {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px;
  padding: 0 16px;

  @include no-results;

  .notes {
    color: $colorTextLightGrey;
  }
}

.with-wrap {
  display: block;
  white-space: normal;
}

.w-50 {
  width: 50%;
  flex: auto !important;

  @include breakpoint(medium) {
    width: auto;
    flex: 1 !important;
  }
}

@include dropdown-footer;

.dropdown-footer {
  .btn-group {
    display: flex;
    flex-direction: column;

    .btn+.btn {
      margin: 0;
    }
  }
}

.filter-status-item {
  .checkbox {
    .checkbox-label {
      display: inline-flex;
      vertical-align: middle;
      align-items: center;
    }
  }
  .svg-icon {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
}

.upper-letter {
  &::first-letter {
    text-transform: uppercase;
  }
}

.bg-danger {
  color: $colorBright2_10;
  background-color: $colorBrightOpacity055_2_10;
}

.bg-warning {
  color: $colorBright2_09;
  background-color: $colorBrightOpacity01_2_09;
}

.bg-success {
  color: $colorBright1_10;
  background-color: $colorBrightOpacity008_1_10;
}

.text-light {
  color: $colorTextLightGrey;
}
