@import "scss/_variables.scss";

.filter-container {
  padding-bottom: 16px;

  &__pin-btn {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    z-index: 1;
    left: 0;
    padding: 0 16px 8px 16px;
    display: flex;
    justify-content: space-between;

    &:before {
      content: "";
      height: 16px;
      width: 100%;
      background-image: linear-gradient(180deg,hsla(0,0%,100%,0) 0,hsla(0,0%,100%,.7));
      position: absolute;
      top: -16px;
      left: 0;
      z-index: -1;
    }

    .btn {
      width: 90px;
      height: 34px;
      font-size: $fontSizeL;
      flex-shrink: 0;

      &.apply {
        width: calc(100% - 100px);
      }
    }
  }
}
