@import 'scss/_variables.scss';

.finance-page-stat-block {
  @include common-card;

  &__head-block {
    display: flex;
    justify-content: space-between;
  }

  .btn {
    height: 44px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 4px;
  }
}
