@import 'scss/_variables.scss';

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 2;
  display: flex;
  padding: 11px 0 13px 0;
  justify-content: center;
  box-shadow: inset 0 -1px 0 0 rgba(198, 198, 198, 0.5);
  @include fontFamilyBold;

  &--only-header {
    justify-content: center;

    .header__page-title {
      margin: -10px;
    }
  }

  &--order {
    justify-content: left;
    align-items: center;
    padding-left: 30px;
    
    .header__page-title {
      margin-right: 0;
    }

    .favorite-wrap {
      padding: 10px;
      margin: -10px;
      margin-right: 16px;
    }
  }

  &__close-btn {
    position: absolute;
    right: 30px;
    top: 50%;
    padding: 10px;
    margin-right: -10px;
    margin-top: -3px;
    transform: translateY(-50%) rotate(-180deg);
    background: transparent;
    border: none;
    
    .svg-icon {
      width: 20px;
      height: 20px;
    }
  }

  &__page-title {
    padding: 10px;
    margin: -10px;
    color: $colorTextDark;
    font-size: 16px;
    line-height: 1.5;
  }

  &__page-icon {
    position: absolute;
    height: 48px;
    top: 0;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 12px;

    &--left {
      left: 12px;
      right: 0;
    }

    .svg-icon {
      width: 20px;
      height: 20px;
    }

  }

  &__page-filter {
    color: $colorAction11;
    &--applied {
      &:before {
        content: "";
        position: absolute;
        background-color: $colorBright2_10;
        width: 5px;
        height: 5px;
        top: 50%;
        border-radius: 50%;
        transform: translate(6px, -10px);
        z-index: 2;
        border: 1px solid #fff;
      }
    }
  }

  &__page-time {
    &--with-text {
      width: auto;
      color: $colorAction11;
    }
    .svg-icon {
      stroke: $colorAction11;
    }
    .time-count {
      margin-left: 5px;
      &:empty {
        display: none;
      }
    }
  }
}
