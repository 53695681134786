@import 'scss/_variables.scss';

.finance-card {
  @include common-card;

  &__title {
    line-height: 1.5;
  }

  &.is-success {
    .combined-shape {
      fill: $colorBrightOpacity20_1_09;
      stroke: $colorBright1_09;
      transform: rotate(100grad);
    }
    .status-container__status {
      color: $colorBright1_09;
    }
    .status-container__price {
      color: $colorBright1_09;
    }
  }

  .combined-shape {
    transform: rotate(300grad);
  }

  .status-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    &__icon-status {
      display: flex;
      align-items: center;
    }

    .btn {
      padding: 6px 12px;
      height: 32px;
      border-radius: 4px;
      border: solid 1px #2383e5;
      color: #2383e5;
      line-height: 1.43;
    }
  }

  .finance-card-tag-date-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    &__tag {
      padding: 4px 8px;
      border-radius: 13px;
      font-size: 14px;

      background-color: rgba(255,166,93, 0.3);
      color: #c94c36;

      + div {
        color: $colorTextLightGrey;
      }

      &--payment {
        background-color: rgba(84,20,180, 0.15);
        color: #3b1b6a;
      }
    }
  }
}
