@import 'scss/_variables.scss';
$border: 1px solid $color05;

@mixin row-left-border {
  &:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.order-card {
  @include common-card;
  background-color: $color03;

  &.not-read {
    background-color: #fff;
    @include fontFamilyBold;
  }
  
  .order-card-header-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #637280;

    &__type {
      border-radius: 13px;
      background-color: #edebeb;
      padding: 3px 12px;
      color: $colorText;
      @include fontFamilyBold;
    }

    &__price-icons {

      .tags-list {
        margin: 0;
        max-width: 14px;
        max-height: 14px;
        margin-left: 5px;
        margin-top: 3px;

       .svg-icon {
          margin-right: 0;
       }

        .tooltip {
          .tooltip-content {
            right: 12px;
          }
        }
      }
    }

    &__number {
      color: $colorText;
  
      .email-notification {
        margin-right: 8px;
        width: 15px;
        height: 12px;
      }
    }

  }

  &__sub {
    color: $colorDark;
    display: flex;

    .pin-icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23FFA65D' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M14.605 7.111L8.383 7.111 6.05 4 16.939 4z' transform='translate(1.155 1.394) rotate(45 11.494 5.556)'/%3E%3Cpath d='M10.889 13.161L0 13.161 1.497 10.05 9.333 10.05z' transform='translate(1.155 1.394) rotate(45 5.444 11.605)'/%3E%3Cpath d='M3.52 14.697L3.52 12.363' transform='translate(1.155 1.394) rotate(45 3.52 13.53)'/%3E%3Cpath d='M12.358 11.303L4.58 11.303 5.358 5.858 11.58 5.858z' transform='translate(1.155 1.394) rotate(45 8.47 8.58)'/%3E%3C/g%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: contain;
      width: 17px;
      height: 17px;
      margin-right: 8px;
    }
  }

  > div {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .date-price-block {
    display: flex;
    justify-content: space-between;
    color: $colorText;
    margin-bottom: 0px!important;

    &__date-block {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 32px;

      .svg-icon {
        width: 24px;
        height: 24px;
        position: absolute;
        left: -3px;
      }

    }
  }

  &.taken {
    cursor: default;
    pointer-events: none;
    font-weight: 500;
    color: $color06;
    min-height: 41px;
    overflow: hidden;

    @include row-left-border;

    &:before {
      background-color: $color06;
    }
    &:after {
      content: "Taken";
      width: 100%;
      height: 100%;
      background-color: rgba(192, 199, 206, 0.1);
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
    }

    &--hide {
      color: transparent;
      animation: hideOrders 1s;
      padding: 0;
      border: none;
      min-height: 0;
      max-height: 0;
    }

    @keyframes hideOrders {
      0% {
        min-height: 41px;
        border: $border;
        padding: 12px 16px;
      }
      100% {
        padding: 0;
        border: none;
        min-height: 0;
      }
    }

  }

  &.new-order {
    background-color: #e5f7f0;
    position: relative;

    &--with-animation {
      animation: showNewOrders 1s;
    }
    
    @keyframes showNewOrders {
      0% {
        max-height: 0;
        padding: 0;
        border: none;
      }
      100% {
        max-height: 126px;
        border: $border;
        padding: 12px 16px;
      }
    }

    &--viewed {
      background-color: $color03;
    }

    @include row-left-border;

    &:before {
      background-color: $colorBright1_09;
    }
  }

  .order-card-tag-reserve-block {
    position: relative;
    min-height: unset;
    @include fontFamilyRegular;
    

    &__reserve-time {
      position: absolute;
      right: 0;
      top: 0;
    }

    .text-warning {
      .got-link {
        color: $colorAction11;
        padding: 15px;
        margin: -15px;
      }
    }

    .my-reserve {
      color: $colorBright_1_07;
    }

    &:empty {
      display: none;
    }
  }

  &.reserve-by-me {
    .order-card-tag-reserve-block {
      min-height: 18px;
      margin-top: 8px;
    }
  }
}
