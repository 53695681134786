@import 'scss/_variables.scss';

.mobile-menu {

  .modal-info {
    max-width: 290px;
  }


  &__item {
    padding: 8px 0;
    line-height: 1.5;
    font-size: 16px;
    
    &--text {
      display: flex;
      align-items: center;
      &:before {
        content: '';
        display: block;
        width: 23px;
        height: 29px;
        margin-right: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
      &.learninglab {
        &:before {
          background-image: url("../img/learninglab.png");
        }
      }
      &.help {
        &:before {
          background-image: url("../img/help.png");
        }
      }
      &.inbox {
        &:before {
          background-image: url("../img/inbox.png");
        }
      }
      &.progress {
        &:before {
          background-image: url("../img/progress.png");
        }
      }
      &.issues {
        &:before {
          background-image: url("../img/issues.png");
        }
      }
      span {
        position: relative;
        @include new-msg;
        &.new--usd:not(.new--warnings):before {
          top: -3px;
        }
        &:before {
          right: 0;
          top: 0;
          left: calc(100% + 4px);
        }
        &.new--usd:not(.new--warnings):after {
          top: -7px;
          right: -23px;
        }
      }
    }
  }

  &__footer-text {
    color: $colorText;
    font-size: 15px;
    margin-top: 20px;
  }
}
