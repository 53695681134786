@import "scss/_variables.scss";

.orders-filter-container {
  .container-item {

    &:not(:last-child) {
      padding: 16px 0 0;
      border-bottom: solid 1px #e2e2e2;
    }

    &:last-child {
      padding-top: 16px;
    }

    h3 {
      margin-bottom: 4px;
      color: $colorDark;
    }
  }
}
